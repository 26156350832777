import { Box, Container, Typography } from "@mui/material";
import style from "./style.module.css";
import rocket from "../../../assets/images/rocket.png";

export function GoBeyond({ refs }: any) {
  return (
    <Box component={"section"} className={style["go-beyond"]}>
      <Container className={style["go-beyond__container"]}>
        <Box>
          <Typography component="h2">Para ir além:</Typography>
          <Typography component="p">
            A VEXT foi criada para ir além de uma imobiliária tradicional. Ela
            foi formada para gerar relacionamentos, ser fonte de conhecimento e
            inovação, através de um modelo de negócio próprio que visa alavancar
            os resultados de clientes, construtoras e corretores.
          </Typography>
        </Box>
        <img src={rocket} alt="" />
      </Container>
    </Box>
  );
}
