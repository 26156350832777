import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { Box, ThemeProvider } from "@mui/material";
import { Header } from "../../sections/AboutUs/Header";
import Footer from "../../sections/Home/Footer";
import { Contact } from "../../sections/Home/Contact";
import defaultTheme from "../../Themes/Default";
import { GoBeyond } from "../../sections/AboutUs/GoBeyond";
import { Partners } from "../../sections/AboutUs/Partners";
import { Plantation } from "../../sections/AboutUs/Plantation";
import { Values } from "../../sections/AboutUs/Values";
import { Matchmaker } from "../../sections/AboutUs/Matchmaker";
import { motion } from "framer-motion";

const AboutUs = () => {
  const location = useLocation();

  // Estado para controlar a abertura do diálogo de contato
  const [openContactDialog, setOpenContactDialog] = useState(false);

  // Função para abrir o diálogo de contato
  const handleOpenContactDialog = () => setOpenContactDialog(true);
  const handleCloseContactDialog = () => setOpenContactDialog(false);

  const headerRef = useRef<HTMLDivElement>(null);
  const partnersRef = useRef<HTMLDivElement>(null);
  const goBeyondRef = useRef<HTMLDivElement>(null);
  const plantationRef = useRef<HTMLDivElement>(null);
  const valuesRef = useRef<HTMLDivElement>(null);
  const matchmakerRef = useRef<HTMLDivElement>(null);

  const refs: any = {
    header: headerRef,
    partners: partnersRef,
    goBeyond: goBeyondRef,
    plantation: plantationRef,
    values: valuesRef,
    matchmaker: matchmakerRef,
  };

  useEffect(() => {
    const scrollToSection = location.state?.scrollTo;

    if (scrollToSection) {
      if (refs[scrollToSection]?.current instanceof HTMLElement) {
        refs[scrollToSection].current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, refs]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          bgcolor: "primary.main",
          overflow: "hidden",
        }}
      >
        <NavBar refs={refs} openContactDialog={handleOpenContactDialog} />
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
          ref={headerRef}
        >
          <Header openContactDialog={handleOpenContactDialog} />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
          ref={goBeyondRef}
        >
          <GoBeyond />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
          ref={partnersRef}
        >
          <Partners />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
          ref={plantationRef}
        >
          <Plantation />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
          ref={valuesRef}
        >
          <Values />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
          ref={matchmakerRef}
        >
          <Matchmaker openContactDialog={handleOpenContactDialog} />
        </motion.div>

        <Footer />

        {/* Renderiza o dialog Contact */}
        <Contact
          openDialog={openContactDialog}
          onCloseDialog={handleCloseContactDialog}
        />
      </Box>
    </ThemeProvider>
  );
};

export default AboutUs;
