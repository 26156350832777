import { Box, Container, Typography } from "@mui/material";
import style from "./style.module.css";
import plant from "../../../assets/images/plant.png";
import VectorV from "../../../assets/images/VectorV.svg";

export function Plantation({ refs }: any) {
  return (
    <Box component={"section"} className={style["plantation"]}>
      <Container className={style["plantation__container"]}>
        <Box>
          <Typography component="span">Cultive O Sucesso</Typography>
          <Typography component="h2">VEXT É PLANTAÇÃO:</Typography>
          <Typography component="p">
            Vext significa plantação em latim.
          </Typography>
          <Typography component="p">
            O mesmo idioma que constituiu religiões, documentos, escrituras e
            transformou culturas, também acompanha o significado da nossa
            empresa. Plantamos para colher, desde bons resultados a progressos
            pessoais, profissionais e familiares.
          </Typography>
          <Typography component="p">
            VEXT: A Fábrica de esticar gente!
          </Typography>
        </Box>
        <Box component="div" className={style["plantation__images"]}>
          <img src={plant} alt="planta germinando" />
          <img src={VectorV} alt="letra v na cor verde" />
        </Box>
      </Container>
    </Box>
  );
}
