import React, { useState, useRef, useEffect } from "react";
import { Box, Container, IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

import "./style.css";

const VideoSection = () => {
  const [playButton, setPlayButton] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    const video: any = videoRef.current;
    if (video) {
      const handleCanPlay = () => {
        setIsVideoPlaying(true);
      };
      video.addEventListener("canplay", handleCanPlay);

      return () => {
        video.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, []);

  const handlePlayButtonClick = () => {
    const video: any = videoRef.current;
    if (video) {
      if (playButton) {
        video.play();
      } else {
        video.pause();
      }
      setPlayButton(!playButton);
    }
  };
  return (
    <Box component="section" className="video-section">
      <Container className="video-container">
        <Box
          component="video"
          autoPlay
          loop
          muted={!isVideoPlaying}
          ref={videoRef}
          className="background-video"
        >
          <source
            src={`${process.env.PUBLIC_URL}/assets/video/main.mp4`}
            type="video/mp4"
          />
          Seu navegador não suporta o vídeo.
        </Box>
        {!isVideoPlaying ? (
          <Box className="video-overlay"></Box>
        ) : (
          <>
            <Box className={`pre-video ${playButton ? "" : "pre-video-left"}`}>
              <IconButton
                onClick={handlePlayButtonClick}
                className="play-button"
              >
                {playButton ? (
                  <PlayArrowIcon fontSize="large" style={{ color: "black" }} />
                ) : (
                  <>
                    <PauseIcon fontSize="large" style={{ color: "black" }} />
                  </>
                )}
              </IconButton>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default VideoSection;
