import { Box, Button, Container, Typography } from "@mui/material";
import style from "./style.module.css";

export function Matchmaker({ refs, openContactDialog }: any) {
  return (
    <Box
      sx={{
        mt: "100px",
        mb: { xs: "30px", sm: "70px" },
        position: "relative",
      }}
    >
      <Box className={style["newSection"]}>
        <Container maxWidth="lg" className={style["newSectionContainer"]}>
          <Box
            className={style["backgroundImage"]}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/banner-nos.png)`,
            }}
          />
          <Typography component="h2" className={style["sectionTitle"]}>
            Quem nunca pensou em ter a possibilidade de estar além do que os
            olhos podem ver?
          </Typography>
          <Box className={style["sectionButtons"]}>
            <Button className={style["contact"]} onClick={openContactDialog}>
              Entrar em contato
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
