import { Box, Container, Typography } from "@mui/material";
import style from "./style.module.css";
import { PartnerCard } from "./PartnerCard";
import joao from "../../../assets/images/joao.png";
import maycon from "../../../assets/images/maycon.png";
import leonam from "../../../assets/images/leonam.png";

export function Partners({ refs }: any) {
  return (
    <Box component={"section"} className={style["partners"]}>
      <Container maxWidth="lg" className={`${style["partners__container"]}`}>
        <Box component="div" className={style["partners__info"]}>
          <Typography component="span">Voe Alto com a Vext</Typography>
          <Typography component="h2">
            A DIRETORIA: + de 30 anos de experiência no mercado imobiliário
          </Typography>
        </Box>
      </Container>
      <Box component="div" className={style["partners__card-area"]}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/banner/bg-hexagon-dark.png`}
          alt=""
        />
        <Container
          maxWidth="lg"
          className={`${style["partners__container"]} ${style["partners__card-container"]}`}
        >
          <PartnerCard
            img={joao}
            title="João andré:"
            content="E formador de líderes no mercado imobiliário, diretor comercial
          apaixonado por pessoas, é um treinador e desenvolvedor de times de
          vendas de alta performance para mercados de médio e alto padrão."
          />
        </Container>
      </Box>
      <Container
        className={`${style["partners__container"]} ${style["partners__card-container"]}`}
      >
        <PartnerCard
          img={maycon}
          title="Maycon Almeida:"
          content="Especialista na formação de corretores e líderes de alta performance, dedicado ao desenvolvimento e mentoria de pessoas, diretor comercial com mais de 10 anos de experiência no mercado imobiliário."
          orientation="right"
        />
      </Container>
      <Box component="div" className={style["partners__card-area"]}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/banner/bg-hexagon-right-dark.png`}
          alt=""
        />
        <Container className={`${style["partners__container"]}`} maxWidth="lg">
          <PartnerCard
            img={leonam}
            title="Leonam Goudinho:"
            content="Gestor de empresas, investidor do mercado imobiliário, financeiro e de startups, é um entusiasta de negócios com crescimento exponencial que preservam a solidez dos processos e indicadores."
          />
        </Container>
      </Box>
    </Box>
  );
}
