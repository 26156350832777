import { CardMedia, CardContent, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import style from "./style.module.css";

interface PartnerCardProps {
  img: string;
  title: string;
  content: string;
  orientation?: "left" | "right";
}

export function PartnerCard({
  img,
  title,
  content,
  orientation = "left",
}: PartnerCardProps) {
  return (
    <Card
      className={style["partners__card"]}
      style={{ flexDirection: orientation === "left" ? "row" : "row-reverse" }}
    >
      <CardMedia
        className={style["partners__card-image"]}
        image={img}
        title={title}
      />
      <CardContent>
        <Typography component="h3">{title}</Typography>
        <Typography component="p">{content}</Typography>
      </CardContent>
    </Card>
  );
}
